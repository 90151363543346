<template>
  <div class="container">
    <h1 class="blind-a11y">Qpick Dashboard</h1>
    <Header />
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import channelService from "@/utils/channel.js";

const ModuleAuth = createNamespacedHelpers("ModuleAuth");
const ModuleAdmin = createNamespacedHelpers("ModuleAdmin");

export default {
  data() {
    return {};
  },
  computed: {
    ...ModuleAdmin.mapGetters(["getDeviceType"]),
    ...ModuleAuth.mapGetters(["getLoginStatus", "getLoggedData"])
  },
  watch: {
    getLoginStatus(newVal) {
      if (!newVal) {
        this.channelTalk(false);
      }
    }
  },
  methods: {
    onDeviceType() {
      let parentWidth = document.documentElement.clientWidth;
      let deviceType = parentWidth < 1024 ? "mobile" : "desktop";
      this.setDeviceType(deviceType);
    },
    userLogged() {
      this.actLogged().then(response => {
        switch (response.status) {
          case 200:
            this.setLoginStatus(true);
            this.channelTalk(true);
            break;

          default:
            this.setLoginStatus(false);
            this.channelTalk(false);
            break;
        }
      });
    },
    //채널톡 실행
    channelTalk(isLogin) {
      let settings = {};
      let profile = {};

      if (isLogin) {
        settings.pluginKey = process.env.VUE_APP_AUTH_CHANNEL_TALK_PLUGIN_KEY;
        settings.member = true;
        profile.name = this.getLoggedData.privacy.name;
        profile.joinTime = this.getLoggedData.createdAt;
        profile.qpickId = this.getLoggedData.privacy.email;
        settings.memberId = this.getLoggedData.id;
        settings.profile = profile;

        channelService.boot(settings);
      } else {
        settings.pluginKey = process.env.VUE_APP_AUTH_CHANNEL_TALK_PLUGIN_KEY;
        settings.member = false;

        channelService.boot(settings);
      }
    },
    ...ModuleAuth.mapActions(["actLogged"]),
    ...ModuleAuth.mapMutations(["setLoginStatus"]),
    ...ModuleAdmin.mapMutations(["setDeviceType"])
  },
  created() {
    channelService.constructor();
  },
  mounted() {
    window.addEventListener("resize", this.onDeviceType);

    // 디바이스 타입
    this.onDeviceType();

    // 로그인된 회원정보
    this.userLogged();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onDeviceType);
  },
  components: {
    Header: () => import("@/components/admin/Header.vue")
  }
};
</script>

<style lang="scss">
html,
body {
  height: 100vh;
  height: -webkit-stretch;
  height: stretch;
  background-color: #f7f9fc;
}
body {
  overscroll-behavior: auto;
  overflow: auto;
}
#app {
  height: 100vh;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;
}
.bothLayout {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  justify-content: space-between;
}
.bothLayout__item {
  position: relative;
  width: calc(50% - 8px);
  padding: 24px 16px 20px;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 8px;
  box-sizing: border-box;
}
.container {
  display: flex;
  flex-direction: column;
}
.main {
  position: relative;
  min-height: calc(100vh - 211px);
  padding: 16px 16px 24px;
  box-sizing: border-box;

  &__container {
    position: relative;
    max-width: $w;
    min-height: 100%;
    margin: 0 auto;
    padding: 16px 16px 24px;
    box-sizing: border-box;
    @include box;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .main {
    padding: 0;

    &__container {
      border-radius: 0;
    }
  }
  .bothLayout__item {
    width: 100%;
  }
}
</style>
