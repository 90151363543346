var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('h1', {
    staticClass: "blind-a11y"
  }, [_vm._v("Qpick Dashboard")]), _c('Header'), _c('router-view', {
    key: _vm.$route.fullPath
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }